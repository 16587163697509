import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import AuthProvider from "acciondigital-basic-theme/src/context/AuthContext"

import infoSitio from "../utils/infoSitio"
console.log("🚀 ~ file: rootElement.js:8 ~ infoSitio.netlifySiteId:", infoSitio.netlifySiteId)

const queryClient = new QueryClient()



export const RootElement = ({ children }) => (
  <AuthProvider sitio={infoSitio.netlifySiteId}>
    <QueryClientProvider client={queryClient}>
      {/* <CardProvider sitio={sitio} saveToFirebase={true} vigenciaDiasDefault={-1}> */}
        {children}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      {/* </CardProvider> */}
    </QueryClientProvider>
  </AuthProvider>
)

export default RootElement
